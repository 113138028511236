'use client'

import { Dialog, Text } from '@vinted/web-ui'

import useTranslate from 'hooks/useTranslate'

const DEFAULT_OFFERS_AMOUNT = 5

type Props = {
  show: boolean
  onClose: () => void
  maxOfferCount?: number
}

const BuyerOfferRequestsExceededModal = ({
  show,
  onClose,
  maxOfferCount = DEFAULT_OFFERS_AMOUNT,
}: Props) => {
  const translate = useTranslate('conversation.hero_message.make_offer')

  return (
    <Dialog
      show={show}
      title={translate('offers_exceeded_modal.title')}
      body={
        <Text
          as="span"
          text={translate('offers_exceeded_modal.body', {
            offersLimit: maxOfferCount,
          })}
          alignment={Text.Alignment.Center}
        />
      }
      actions={[
        {
          text: translate('offers_exceeded_modal.actions.got_it'),
          style: Dialog.ActionStyling.Filled,
          callback: onClose,
        },
      ]}
    />
  )
}

export default BuyerOfferRequestsExceededModal
