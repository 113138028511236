export enum ShipmentStatus {
  ShipmentInstructionsConfirmed = 250,
}

export enum ItemShippingDetailsDiscountType {
  All = 'all',
  Home = 'home',
  DropOff = 'drop_off',
}

export enum ShipmentEntryType {
  Preliminary = 20,
  Factual = 1,
  Return = 30,
}

export enum ShipmentDeliveryType {
  Home = 1,
  Pudo = 2,
}
