import { PriceEstimateWithFeesDto } from 'types/dtos'
import { PriceEstimateWithFeesModel } from 'types/models'

import { transformCurrencyAmountDto } from './currency-amount'
import { transformBuyerProtectionFee, transformShippingFee } from './escrow-fees'

export const transformPriceEstimateWithFees = (
  dto: PriceEstimateWithFeesDto,
): PriceEstimateWithFeesModel => ({
  totalPrice: transformCurrencyAmountDto(dto.total_price),
  buyerProtection: transformBuyerProtectionFee(dto.buyer_protection),
  shipping: transformShippingFee(dto.shipping),
})
