'use client'

import { FormEventHandler, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Note, Dialog, Button, Divider, Navigation, InfoBanner, Text } from '@vinted/web-ui'
import { X24 } from '@vinted/monochrome-icons'
import uuid from 'uuid'

import useAbTest from 'hooks/useAbTest'
import useTracking from 'hooks/useTracking'
import useTranslate from 'hooks/useTranslate'

import { startMakeOfferRequestEvent, viewScreenEvent } from 'libs/common/event-tracker/events'

import { CurrencyAmountModel, OfferRequestPriceSuggestionModel } from 'types/models'

import { Screen } from 'constants/tracking/screens'
import { SERVICE_FEE_HELP_URL } from 'constants/routes'

import { getOfferRequestSessionId } from 'state/conversation/selectors'
import { actions } from 'state/conversation/slice'

import OfferSubmitButton from '../OfferSubmitButton/OfferSubmitButton'
import OfferPriceField from '../fields/OfferPriceField'
import OfferPriceWithSuggetionsField from '../fields/OfferPriceWithSuggetionsField'
import ItemSummary from '../ItemSummary/ItemSummary'

type Props = {
  maxDiscount?: string
  transactionId?: number
  remainingOffers?: number
  offerPriceSuggestions?: Array<OfferRequestPriceSuggestionModel>
  show: boolean
  minItemPrice?: CurrencyAmountModel
  maxItemPrice?: CurrencyAmountModel
  thresholdPrice: string | null
  isSubmitting: boolean
  itemPrice: CurrencyAmountModel
  onSubmit: FormEventHandler
  onClose: () => void
  itemId: number
  itemTitle?: string
  itemThumbnailUrl?: string | null
  orderItemIds?: Array<number>
}

const BuyerOfferRequestDialog = ({
  show,
  onClose,
  onSubmit,
  itemPrice,
  maxDiscount,
  isSubmitting,
  minItemPrice,
  maxItemPrice,
  transactionId,
  thresholdPrice,
  remainingOffers,
  offerPriceSuggestions,
  itemId,
  itemTitle,
  itemThumbnailUrl,
  orderItemIds,
}: Props) => {
  const { track } = useTracking()
  const dispatch = useDispatch()

  const translate = useTranslate('conversation.hero_message.make_offer')

  const offerRequestSessionId = useSelector(getOfferRequestSessionId)

  const isOfferRequestPriceSuggestionEnabled = !!offerPriceSuggestions?.length
  const thresholdBannerKey = 'offer_modal.threshold_banner_title.buyer'

  useEffect(() => {
    if (!show) return

    track(viewScreenEvent({ screen: Screen.BuyerOriginatingOffer }))
  }, [show, track])

  useEffect(() => {
    if (!show) return

    dispatch(actions.setOfferRequestSessionId({ offerRequestSessionId: uuid.v4() }))
  }, [dispatch, show])

  useEffect(() => {
    if (!show || !offerRequestSessionId) return

    track(
      startMakeOfferRequestEvent({
        offerRequestSessionId,
        currentPrice: Number(itemPrice.amount),
        hasSuggestedPrices: isOfferRequestPriceSuggestionEnabled,
      }),
    )
  }, [isOfferRequestPriceSuggestionEnabled, itemPrice.amount, offerRequestSessionId, show, track])

  useAbTest({ abTestName: 'maximum_offer_request_discount', shouldTrackExpose: true })

  function renderNavigation() {
    return (
      <Navigation
        body={translate('offer_modal.title')}
        right={
          <Button
            inverse
            inline
            iconName={X24}
            testId="offer-modal-navigation-close-button"
            onClick={onClose}
          />
        }
        testId="offer-modal-navigation"
      />
    )
  }

  function renderRemainingOffersNote() {
    if (!remainingOffers) return null

    return <Note text={translate('offer_modal.caption', { remaining_offers: remainingOffers })} />
  }

  return (
    <Dialog show={show} testId="offer-modal">
      <form onSubmit={onSubmit}>
        {renderNavigation()}
        <Divider />
        {thresholdPrice && !isOfferRequestPriceSuggestionEnabled && (
          <InfoBanner
            body={
              <Text
                as="span"
                text={translate(thresholdBannerKey, {
                  amount: thresholdPrice,
                  'learn-more': chunks => (
                    <a href={SERVICE_FEE_HELP_URL} target="_blank" rel="noreferrer">
                      {chunks}
                    </a>
                  ),
                })}
                html
              />
            }
            type={InfoBanner.Type.Info}
          />
        )}
        <ItemSummary
          price={itemPrice}
          title={itemTitle}
          thumbnailUrl={itemThumbnailUrl}
          itemCount={orderItemIds?.length}
        />
        {isOfferRequestPriceSuggestionEnabled ? (
          <OfferPriceWithSuggetionsField
            maxDiscount={maxDiscount}
            minItemPrice={minItemPrice}
            maxItemPrice={maxItemPrice}
            currentItemPrice={itemPrice}
            transactionId={transactionId}
            offerPriceSuggestions={offerPriceSuggestions}
            itemId={itemId}
            orderItemIds={orderItemIds}
          />
        ) : (
          <OfferPriceField
            maxDiscount={maxDiscount}
            minItemPrice={minItemPrice}
            maxItemPrice={maxItemPrice}
            currentItemPrice={itemPrice}
            itemId={itemId}
            orderItemIds={orderItemIds}
          />
        )}
        {thresholdPrice && isOfferRequestPriceSuggestionEnabled && (
          <InfoBanner
            body={
              <Text
                as="span"
                text={translate(thresholdBannerKey, {
                  amount: thresholdPrice,
                  'learn-more': chunks => (
                    <a href={SERVICE_FEE_HELP_URL} target="_blank" rel="noreferrer">
                      {chunks}
                    </a>
                  ),
                })}
                html
              />
            }
            type={InfoBanner.Type.Info}
          />
        )}
        {renderRemainingOffersNote()}
        <OfferSubmitButton
          currency={itemPrice.currencyCode}
          isSubmitting={isSubmitting}
          isOfferPriceDisplayEnabled={isOfferRequestPriceSuggestionEnabled}
        />
      </form>
    </Dialog>
  )
}

export default BuyerOfferRequestDialog
