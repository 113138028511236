'use client'

import { useSelector } from 'react-redux'
import { useWatch } from 'react-hook-form'
import { Cell, Button } from '@vinted/web-ui'

import useTranslate from 'hooks/useTranslate'

import { getLocale } from 'state/intl/selectors'

import { formatCurrency } from 'libs/utils/formatString'

import { OfferFormField } from '../types'

type Props = {
  isOfferPriceDisplayEnabled?: boolean
  currency: string
  isSubmitting: boolean
}

const OfferSubmitButton = ({
  currency,
  isSubmitting,
  isOfferPriceDisplayEnabled = false,
}: Props) => {
  const translate = useTranslate('conversation.hero_message.make_offer')

  const locale = useSelector(getLocale)

  const offerValue = useWatch({ name: OfferFormField.Offer, disabled: !isOfferPriceDisplayEnabled })

  function renderTitle() {
    if (isOfferPriceDisplayEnabled) {
      return translate('offer_modal.actions.submit_with_price', {
        price: offerValue ? formatCurrency(offerValue, { locale, currency }) : '',
      })
    }

    return translate('offer_modal.actions.submit')
  }

  return (
    <Cell>
      <Button
        disabled={isSubmitting}
        isLoading={isSubmitting}
        type={Button.Type.Submit}
        styling={Button.Styling.Filled}
        testId="offer-submit-button"
        onClick={(event: React.MouseEvent<HTMLAnchorElement | HTMLButtonElement>) => {
          if (isSubmitting) {
            event.preventDefault()
          }
        }}
      >
        {renderTitle()}
      </Button>
    </Cell>
  )
}

export default OfferSubmitButton
